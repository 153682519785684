import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@akinon/next/components/client-root.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@akinon/next/components/pz-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/app/node_modules/@akinon/next/components/web-vitals.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/utils/get-currency.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[commerce]/[locale]/[currency]/client-root.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/assets/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/root-modal.tsx");
